import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Gallery from "../../../components/gallery"

const DMLG2021 = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: { regex: "/meisterschaft/dm-lg-2021/gallery_.*.jpeg/" }
        }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Deutsche Meisterschaft 2021 - Luftgewehr" lang="de" />
      <Container>
        <h1 className="h3">Deutsche Meisterschaft 2021 - Luftgewehr</h1>
        <hr className="featurette-divider" />
        <p>
          Eine kleine Gallerie mit einigen Bildern zu den Deutschen
          Meisterschaften 2021 in der Disziplin Luftgewehr über 10m. Der SV
          Gambach wurde hierbei verteten durch:
        </p>
        <ul>
          <li>Heiko Lippert</li>
        </ul>
        <Row className="mt-4">
          <Col className="col-md-6 offset-md-3">
            <Gallery images={data.allFile.edges} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default DMLG2021
